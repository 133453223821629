/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@font-face {
  font-family: "ArialBold";
  src: local("ArialBold"), url("./fonts/ARIALBD.TTF") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "ArialReg";
  src: local("ArialReg"), url("./fonts/ArialCE.ttf") format("truetype");
  font-weight: normal;
}

body {
  /* font-family: "ArialReg" !important; */
  font-family: "Open Sans", sans-serif !important;
}

* {
  font-family: "Open Sans", sans-serif !important;
}

.downward {
  color: #cc0605;
}

.flex {
  display: flex;
}

.relative {
  position: relative;
}

.w-full {
  width: 100%;
}

.w-full {
  width: 100%;
}

.relative {
  position: relative;
}

.w-full {
  width: 100%;
}

.text-center {
  text-align: center;
}

.w-1\/2 {
  width: 50%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.w-2\/5 {
  width: 40%;
}

.primary-color {
  color: #184183 !important;
}

.font-bold {
  font-weight: 700;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.flex {
  display: flex;
}

.left-0 {
  left: 0px;
}

.top-1\/2 {
  top: 50%;
}

.absolute {
  position: absolute;
}

.upward {
  color: #06ab04;
}

.downward img,
.upward img {
  width: 15px;
}

.new-added-users {
  color: #48a4da;
}

.rounded-icon {
  width: 40px !important;
  height: 40px !important;
  border-radius: 100%;
}

.para {
  color: #50504f;
  font-size: 0.8rem;
}

.primary-color {
  color: #184183 !important;
}

.btn2 {
  border: 2px solid #184183 !important;
  border-radius: 0% !important;
  padding: 0px !important;
  color: #184183 !important;
  font-weight: bold !important;
}

.btn2 .minus {
  background-color: #184183;
}

.btn2.medium {
  padding: 3px 10px !important;
}

.post-img {
  max-width: 70px;
  height: auto;
}

.hover-row {
  cursor: pointer;
}

.hover-row:hover .eye-icon {
  display: block;
}

.eye-icon {
  width: 30px;
  padding: 0 5px;
  display: none;
}

table .icon {
  width: 50px;
}

textarea {
  outline: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

textarea:hover {
  border-bottom: 2px solid #000;
}

textarea:focus {
  border-bottom: 2px solid #184183;
}

.MuiInput-underline:before {
  content: "" !important;
}

.search-wrapper input {
  border: 0 !important;
  outline: none !important;
}

.primary-btn {
  border: none;
  background-color: white;
  color: #184183;
  outline: none;
  width: auto;
  padding: 0.5rem 1.5rem;
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0 !important;
}

.secondary-btn {
  border: none;
  background-color: #184183 !important;
  color: #fff !important;
  outline: none;
  width: auto;
  padding: 0.5rem 1.5rem !important;
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0 !important;
}

button .minus {
  width: 8px;
  height: 2px;
  background-color: #fff;
  margin-right: 8px;
}

.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-form input {
  width: 100%;
  border: none;
  padding: 0.5rem 1.5rem;
  background-color: #fff !important;
  margin-bottom: 2rem;
  outline: none;
}

.auth-form button {
  max-width: 150px;
}

.auth-container {
  background: linear-gradient(41deg, #fcad73 35%, #f3535f 100%);
  padding: 4rem 0;
  position: relative;
  box-shadow: 2px 6px 20px #dededec7, 0 6px 6px #dededec7;
}

/* .auth-container::before {
  position: absolute;
  content: " ";
  width: 100%;
  height: 10px;
  background-color: #F67F8A;
  left: 0;
  bottom: 10px;
}

.auth-container::after {
  position: absolute;
  content: " ";
  width: 100%;
  height: 10px;
  background-color: #184183;
  left: 0;
  bottom: 0px;
} */

.text-btn {
  color: #636363;
  font-size: 12px;
  margin-top: 1rem;
  cursor: pointer;
}

.text-btn2 {
  margin-left: 5px;
  display: inline-block;
  border-bottom: 1px solid #bec0c7;
  color: #bec0c7;
  line-height: 1;
  font-size: 0.8rem;
  cursor: pointer;
}

.button-1 {
  width: auto;
  min-width: 100px;
  border: 0;
  border-radius: 0;
  background-color: #fa7714;
  color: #184183;
  font-size: 0.9rem;
  text-transform: uppercase;
  padding: 0.5rem;
  font-weight: 600;
}

.tab_button-1 {
  width: auto;
  min-width: 100px;
  border: 0;
  border-radius: 0;

  color: #184183;
  font-size: 0.9rem;
  text-transform: uppercase;
  padding: 0.5rem;
  font-weight: 600;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: #184183 !important;
}

.custom-tab-btn {
  border: 0 !important;
  color: #bec0c7 !important;
  font-weight: 700;
  cursor: pointer;
}

.custom-tab-btn.active {
  color: #184183 !important;
  font-weight: 700;
  letter-spacing: 0.5px;
  /* border-bottom: 3px solid #184183 !important; */
}

/* Layout */
.layout {
  display: flex;
}

.side-nav {
  width: 240px;
  height: 100vh;
}

.page-container {
  width: calc(100% - 240px);
  height: 100vh;
  background-color: #f6f6f6;
}

.header {
  width: 100%;
  height: 70px;
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 5px 5px 25px #dededecc;
}

.navbar_active {
  color: #184183;
}

.notification_rounded {
  border-radius: 100%;
}

.header .search-wrapper {
  width: 100%;
  max-width: 500px;
  height: 100%;
}

.body-container {
  height: calc(100vh - 70px);
  padding-top: 48px;
  overflow-y: auto;
  background-color: #fff8f3;
}

.inner-header {
  width: calc(100% - 240px) !important;
  position: fixed !important;
  top: 70px;
}

/* Layout */

/* SideNav */
#sideNav {
  background-color: linear-gradient(135deg, #F67F8A, #F34F5F);
  padding: 0 2.5rem;
  color: #fff;
  background-image: url("./images/side_nav_background.png");
  /* background-repeat: no-repeat; */
  background-position: center;
  /* width: 105px; */
  background-size: cover;
  height: 100%;
  /* border-radius: 5px; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

#sideNav .profile-info {
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  /* border-bottom: 2px solid #F67F8A; */
}

#sideNav .profile-info .title {
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

#sideNav .profile-info .sub-title {
  color: #F67F8A;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

#sideNav .navlist {
  height: calc(100vh - 50vh);
}

.h1p5 {
  height: 1.5rem;
}

#sideNav .navlist ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;
  padding-bottom: 8rem;
}

/* SideNav */

/* Custom-list */
.custom-list li {
  font-size: 0.8rem;
  color: #636363;
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  padding: 0.3rem 0;
  margin-bottom: 0.5rem;
}

/* Custom-list */

/* Tables */
.primary-table-theme .head {
  background-color: #dee9f9 !important;
  color: #184183 !important;
  border: 1px solid #dee9f9 !important;
  font-size: 0.8rem;
  z-index: 9;
  text-align: left;
}

.table-theme-1 th {
  position: sticky;
  top: 0px;
  background-color: #dee9f9 !important;
  color: #184183 !important;
  border: 1px solid #dee9f9 !important;
  padding: 0.5rem;
  font-size: 0.8rem;
  z-index: 9;
  text-align: left;
}

.table-theme-1 tr {
  box-shadow: none;
}

.table-theme-1 tr:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.table-theme-1 td {
  position: relative;
  border: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  background-color: #fff !important;
  padding: 1rem 0.5rem;
  font-size: 0.8rem;
  text-align: left;
}

.table-theme-1 td::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 5px;
  height: 1px;
  background-color: #f6f6f6;
}

.table-theme-1 td::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 5px;
  height: 1px;
  background-color: #f6f6f6;
}

.custom-table .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dee9f9;
  color: #184183;
  font-size: 0.8rem;
  z-index: 5;
}

.custom-table .head li {
  padding: 1rem 2rem;
}

#manage-tab .head li:nth-child(1) {
  width: 30%;
}

#manage-tab .head li:nth-child(2) {
  width: 15%;
}

#manage-tab .head li:nth-child(3) {
  width: 15%;
}

#manage-tab .head li:nth-child(4) {
  width: 15%;
}

#manage-tab .head li:nth-child(5) {
  width: 25%;
}

.custom-table .body {
  padding-left: 1rem;
  padding-right: 1rem;
}

.custom-table .custom-row {
  display: flex;
  padding-top: 3rem;
  padding-bottom: 2rem;
  height: 480px;
  border-bottom: 1px solid #f6f6f6;
}

.custom-table .body .left {
  width: 30%;
  padding: 1rem;
}

.custom-table .body .right {
  width: 70%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-table .body .comment-section {
  height: 70%;
  overflow-y: auto;
}

.post-detail .header,
.post-polling .header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: -8%;
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.6rem;
  color: #184183;
  font-size: 0.9rem;
  height: auto;
  z-index: 2;
}

.post-detail .header img,
.post-polling .header img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 10px;
  border: 2px solid #fff;
}

.post-detail .header img.home,
.post-polling .header img.home {
  width: 55px;
  height: 55px;
}

.post-detail.without-text .footer {
  padding-top: 50px;
}

.post-detail .footer .title {
  padding-top: 0.5rem;
  color: #184183;
}

.post-detail .footer .text {
  font-size: 0.8rem;
  color: #50504f;
}

.post-polling.modal-view,
.post-detail.modal-view {
  display: flex;
  justify-content: space-between;
}

.post-polling.modal-view .content,
.post-detail.modal-view .content {
  width: 50%;
}

.post-polling.modal-view .footer,
.post-detail.modal-view .footer {
  width: 45%;
}

.post-polling.modal-view .footer h3,
.post-detail.modal-view .footer h3 {
  margin-bottom: 10px !important;
  border: 0 !important;
}

.polling {
  font-size: 0.8rem;
  color: #50504f;
  padding: 0.5rem;
  background-color: #fff;
  margin-top: 1rem;
  cursor: pointer;
  transition: 0.5s all ease;
  position: relative;
}

.polling span {
  position: relative;
  /* z-index: 9; */
  color: #184183;
  font-weight: 600;
}

.polling .progress-bar {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #dee9f9;
}

.polling .progress-text {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #184183;
  font-weight: 600;
}

.custom-table .comment-lists {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #50504f;
  border-top: 0.9px solid #f6f6f6;
  border-bottom: 0.9px solid #f6f6f6;
  padding: 0.3rem 0;
  margin-bottom: 0.5rem;
}

.custom-table .comment-lists li {
  display: flex;
  align-items: center;
}

.custom-table .comment-lists li:nth-child(1) {
  width: 25%;
}

.custom-table .comment-lists li:nth-child(2) {
  width: 23%;
}

.custom-table .comment-lists li:nth-child(3) {
  width: 22%;
}

.custom-table .comment-lists li:nth-child(4) {
  width: 32%;
}

.custom-table .comment-lists li:nth-child(1) img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}

/* Tables */

/* Modal */
#custom-modal {
  margin: 0 auto;
  width: 100%;
  border: 0;
  outline: none;
  padding: 2rem 5rem 5rem;
  max-height: 90vh;
  overflow-y: scroll;
}

#custom-modal .title {
  font-size: 1.2rem;
  color: #184183;
  font-weight: 600;
  width: auto;
  margin-bottom: 3rem;
  text-transform: uppercase;
  border-bottom: 2px solid #184183;
}

#custom-modal .label,
#custom-modal .radio-label span {
  font-size: 1rem;
  color: #184183;
  font-weight: 600;
  width: auto;
}

#custom-modal .radio-label {
  margin-right: 2rem;
}

#custom-modal .cross {
  position: relative;
  display: block;
  margin-left: auto;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 2rem;
  right: -3rem;
}

#custom-modal .cross::before {
  content: " ";
  width: 2px;
  height: 100%;
  background-color: #000;
  top: -2px;
  right: 99px;
  position: absolute;
  transform: rotate(134deg) translate(2.5px, -0.5px);
  right: 8px;
}

#custom-modal .cross::after {
  content: " ";
  width: 2px;
  height: 100%;
  background-color: #000;
  top: -7px;
  right: 19px;
  position: absolute;
  transform: rotate(225deg) translate(-9.5px, 0px);
  right: 16px;
}

#custom-modal .warning-modal .title {
  color: #cb0000;
  border-bottom: 2px solid #cb0000;
  text-align: center;
}

#custom-modal .warning-modal p {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

#custom-modal .warning-modal button {
  border-width: 1px !important;
  font-weight: 400 !important;
  padding: 0.3rem 2rem !important;
}

#custom-modal .warning-modal .approve_decline_padding {
  background-color: #cb0000;
  color: #fff !important;
}

#custom-modal .warning-modal .approve_decline_padding .cross-icon::before,
#custom-modal .warning-modal .approve_decline_padding .cross-icon::after {
  background-color: #fff;
}

/* Modal */

/* Form Styling */
.custom-form-element {
  border: 0 !important;
  border-bottom: 1px solid #f6f6f6 !important;
  border-radius: 0 !important;
}

.bg_none_imp {
  background-color: #fff !important;
  color: black !important;
}

/* Form Styling */
.approve_decline_padding.danger-bg {
  background-color: #cb0000;
  color: #fff !important;
}

.approve_maintenace_padding.yellow-bg {
  background-color: yellow;
  color: #184183 !important;
  font-weight: bold;
}

.approve_maintenace_padding.yellow-bg:hover {
  background-color: yellow !important;
  color: #184183 !important;
}

.approve_decline_padding.danger-bg .cross-icon::before {
  background-color: #fff;
}

.approve_decline_padding.danger-bg .cross-icon::after {
  background-color: #fff;
}

.approve_decline_padding.danger-bg:hover {
  color: #cb0000 !important;
}

.approve_decline_padding.danger-bg:hover .cross-icon::before {
  background-color: #cb0000;
}

.approve_decline_padding.danger-bg:hover .cross-icon::after {
  background-color: #cb0000;
}

.approve_decline_padding .cross-icon {
  position: relative;
  width: 15px;
  height: 15px;
}

.approve_decline_padding .cross-icon::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 15px;
  background-color: #cb0000;
  top: 0;
  left: 0;
  transform: rotate(45deg);
}

.approve_decline_padding .cross-icon::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 15px;
  background-color: #cb0000;
  top: 0;
  left: 0;
  transform: rotate(315deg);
}

.approve_decline_padding.cross-icon {
  position: relative;
}

.MuiButton-outlinedSecondary {
  color: #cb0000 !important;
  border: 1px solid #cb0000 !important;
}

/* request page */
.approval_button_style.mr-10 {
  margin-right: 2rem;
}

.approve_decline_padding.ml-10 {
  margin-left: 2rem;
}

.approval_button_style {
  color: #06ab04 !important;
  border-color: #06ab04 !important;
  border-radius: 0 !important;
}

.approve_decline_padding {
  border-radius: 0 !important;
}

/* request page */

/* event page */
.text_tosmall_10 {
  font-size: 10px;
}

.react-calendar {
  width: 95%;
  background: none;
  line-height: 1.125em;
  box-shadow: 5px 5px 25px #dededecc;
  border: none;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  display: block !important;
}

.react-calendar button {
  padding: 15px 0px;
  margin: 15px 0;
  color: #50504f;
}

abbr[title] {
  text-decoration: none;
  color: #184183;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  background: #dee9f9;
}

.react-calendar__tile--now,
.react-calendar__tile--active {
  background: none;
}

.react-calendar__tile--now abbr {
  background: #184183;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
}

.react-calendar__tile--active abbr {
  background: #F67F8A;
  color: #184183;
  font-weight: bold;
  padding: 10px;
  border-radius: 50%;
}

.cutome_calander_class abbr {
  position: relative;
  padding: 10px;
}

.cutome_calander_class abbr::after {
  content: ".";
  position: absolute;
  bottom: 10%;
  left: 44%;
  color: #184183;
  font-weight: bold;
}

.react-calendar__navigation button span {
  color: #184183;
  font-weight: bold;
}

.react-calendar__navigation button {
  color: #bec0c7;
}

.react-calendar__month-view__days {
  background-color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: none;
}

.react-calendar__tile--now:enabled:hover {
  background: #e6e6e6;
}

.react-calendar__navigation__label {
  width: 10rem;
}

/* event page */

.row-border-bottom {
  width: 80%;
  margin: 2rem auto;
  background-color: #f6f6f6;
  height: 2px;
}

.removed-post.divider {
  position: relative;
}

.removed-post .avatar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 5px;
}

.removed-post.divider::before {
  content: "";
  position: absolute;
  left: -9%;
  top: 15%;
  display: block;
  width: 2px;
  height: 80%;
  background-color: #f6f6f6;
}

.media-box {
  display: flex;
  justify-content: center;
  color: #bec0c7;
  font-size: 1rem;
  box-shadow: 5px 5px 20px #d4d4d4b3;
  padding: 1rem;
  max-width: 90%;
  cursor: pointer;
}

.media-box img {
  margin-right: 5px;
  max-width: 100%;
}

.floating-box {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #184183;
  color: #fff;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-green {
  background-color: rgb(10, 180, 10);
}

.bg-orange {
  background-color: #ffaa00;
}

.headersdesing {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  color: #09090d;
}

.subheaderdesign {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #383c41;
}

.textheader {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  color: #09090d;
}

.text_description {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
}

.radio-group {
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.MuiFormControlLabel-root span {
  width: max-content;
}

.latest-post-card {
  height: 360px;
}

.smallHEaderdesign {
  background-color: #fcad73;
  min-width: 2rem !important;
  min-height: 2rem !important;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-family: "GillSansMedium";
  color: #52575d;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.small_spinner_design {
  /* padding-right: 10px;
  margin-right: 10px; */
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(117, 234, 156) rgb(117, 234, 156) transparent;
  border-image: initial;
  display: inline-block;
  animation: 0.75s linear 0s infinite normal both running
    react-spinners-ClipLoader-clip;
}
.comment-bg {
  background-color: #f6f6f6;
}

.comments_headerdesing {
  font-weight: bold;
  color: #dedfe5;
  text-decoration: none;
  padding: 8px 5px;
  border-radius: 3px 15px 0 0;
  background-color: #2e2f3a;
}

.a {
  fill: #bec0c7;
  transition: all 0.2 ease;
}

.a:hover {
  fill: #184183;
}

/*  */

.header_modal_design {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fcad73;
  padding: 1.2rem;
}

.modal_heading {
  font-size: 1rem;
  color: #131523;
  font-weight: 500;
}

.modal_height_desing {
  height: calc(100vh - 219.83px);
  overflow: auto;
  padding: 1rem 1.5rem;
}

.modal_bottom_desing {
  display: flex;
  border-top: 1px solid #e6e6e9;
  padding: 1rem 1.5rem;
}

.Emailbutton {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 5px;
  /* background-color: #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: #fcad73;
  font-family: "GillSansMedium";
  border: 1px solid #fcad73;
}

.Emailbutton:hover {
  background-color: #fcad73;
  color: #fff;
  border: 1px solid #fcad73;
}

.comment-section {
  max-height: 300px;
  overflow-y: auto;
}

.comment-section::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.comment-section ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.comment-section::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.comment-section ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sticky-sidebar {
  height: calc(100vh - 170px);
  overflow-y: auto;
}

.hub-logo {
  max-width: 40px;
}

.addicon {
  max-width: 35px;
  margin-left: 10px;
}

.addtext {
  color: #184183;
  cursor: pointer;
  text-transform: uppercase;
}

.flag {
  max-width: 30px;
}

.round-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user_data_admin {
  background-color: #fff;
  box-shadow: 5px 5px 25px #dededecc;
}

.ImageShadow {
  box-shadow: 5px 5px 25px #dededecc;
}

.imageMarginTop {
  margin-top: -60px;
}

.partyBoxShadow {
  box-shadow: 5px 5px 25px #eeeeee;
}

.customeCheckbox label .MuiFormControlLabel-label {
  color: #50504f;
}

.customeCheckbox label span span .MuiSvgIcon-root {
  fill: #06ab04;
}

/* otp */
.otpSection input {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 4px;
  margin: 0 0.6rem;
  background-color: #ccc;
}

.otpSection input:focus {
  outline: none;
}

/* otp */

.RotateArrowSign {
  transform: rotate(90deg);
}

/* Editor Start */
.editorSectionStart {
  margin-top: 20px;
  border: 1px solid #bec0c7;
  min-height: 20rem;
}

.codex-editor__redactor {
  padding-bottom: 0px !important;
}

.editorClassName {
  margin-left: 2rem;
  margin-right: 2rem;
}

/* Editor Start */

/* changePassword */
.password_inputField_div {
  width: 100%;
}

.password_inputField_div input {
  margin-bottom: 2rem !important;
}

.password_inputField_div p {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

/* changePassword */

.table_column_min_width {
  min-width: 15rem;
}

.cross_icon_margin {
  margin-right: 4rem !important;
}

.bulkUploadOverFlow {
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.bulkUploadOverFlow::-webkit-scrollbar {
  /* -webkit-appearance: none; */
  -webkit-overflow-scrolling: auto;
}
